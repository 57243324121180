<template>
  <v-snackbar
    v-model="display"
    :timeout="timeout"
    :color="color"
    class="pa-5"
    rounded="pill"
    bottom
  >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn icon rounded v-bind="attrs" @click="display = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { eventBus } from "../../services/eventBus";
export default {
  name: "SnackBarComponent",
  data: () => ({
    message: "",
    color: "",
    timeout: -1,
    display: false,
  }),

  mounted() {
    eventBus.$on("makeAlert", (x) => {
      this.show(x.message, x.color);
    });
  },

  methods: {
    show(message, color = "teal", timeout = 4000) {
      this.message = message;
      this.color = color;
      this.timeout = timeout;
      this.display = true;
    },
  },
};
</script>
