<template>
  <div>
    <v-app-bar color="grey lighten-4" app>
      <v-img
        class="mx-2"
        src="img/indosur.png"
        max-height="100"
        max-width="100"
        contain
      ></v-img>
      <v-btn text color="teal" to="/admin/receptions"> Recepciones </v-btn>
      <v-spacer />
      <v-btn text color="teal" to="/"> Inicio </v-btn>
      <SessionComponent/>
    </v-app-bar>

    <v-main>
      <v-container >
        <router-view />
      </v-container>
    </v-main>
    <SnackBarComponent />
  </div>
</template>

<script>
import SnackBarComponent from "../components/shared/SnackBarAlertComponent.vue";
import SessionComponent from "../components/shared/SessionComponent.vue";

export default {
  components: {
    SnackBarComponent,
    SessionComponent,
  },
};
</script>
