<template>
  <div>
    <template v-if="!user">
      <v-btn text color="teal" @click.stop="dialog = true"> Ingresar </v-btn>
      <v-dialog v-model="dialog" max-width="400px">
        <LoginComponent isDialog="true" @dialog="dialog = false" />
      </v-dialog>
    </template>
    <template v-else>
      <v-menu left close-on-click offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="teal" text dark v-bind="attrs" v-on="on">
            <span v-if="userType === 'Pharmacy'">
              {{ pharmacy.name }}
            </span>
            <span v-else>
              {{ user.username }}
            </span>
          </v-btn>
        </template>
        <v-list class="pa-5">
          <v-list-item v-if="userType === 'Pharmacy'">
            <v-list-item-icon><v-icon>mdi-hospital</v-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ pharmacy.name }}
              </v-list-item-title>
              <v-list-item-subtitle>Farmacia</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon><v-icon>mdi-account</v-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.username }}
              </v-list-item-title>
              <v-list-item-subtitle>Usuario</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <div v-if="userType === 'Pharmacy'">
            <v-list-item>
              <v-btn block text to="/pharmacy/account"> Mi cuenta </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn block text to="/pharmacy/recipes"> Mis recetas </v-btn>
            </v-list-item>
          </div>
          <div v-else-if="userType === 'Admin'">
            <v-list-item>
              <v-btn block text to="/admin/receptions"> Recepciones </v-btn>
            </v-list-item>
          </div>
          <div v-else-if="userType === 'Mesadeentrada'">
            <v-list-item>
              <v-btn block text to="/mesadeentrada/devolutions">
                Devoluciones
              </v-btn>
            </v-list-item>
          </div>
          <div v-else-if="userType === 'Technicaladministration'">
            <v-list-item>
              <v-btn block text to="/technical-administration/recipes">
                Recetas
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn block text to="/technical-administration/historical">
                Histórico
              </v-btn>
            </v-list-item>
          </div>
          <div v-else-if="userType === 'iosper'">
            <v-list-item>
              <v-btn block text to="/iosper/recipes"> Recetas </v-btn>
            </v-list-item>
          </div>
          <v-list-item>
            <v-btn color="teal" block text @click="onLogout">
              Cerrar sesion
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import LoginComponent from "./LoginComponent.vue";

export default {
  name: "SessionComponent",

  components: {
    LoginComponent,
  },

  data: () => ({
    dialog: false,
  }),

  mounted() {
    this.getSession();
    this.loadPharmacy();
  },

  computed: {
    ...mapGetters("session", ["user", "userType"]),
    ...mapGetters("pharmacy", ["pharmacy"]),
  },

  methods: {
    ...mapActions("session", ["getSession", "logout"]),
    ...mapActions("pharmacy", ["loadPharmacy"]),

    async onLogout() {
      await this.logout();
      this.$router.push("/");
    },
  },
};
</script>
